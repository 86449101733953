import { logger } from "@octopusdeploy/logging";
import type { Client, RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
import { Repository } from "@octopusdeploy/octopus-server-client";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { v4 } from "uuid";
import { useOctopusClient } from "../OctopusClientContext";
import type { MutationActionsForMutation } from "./MutationContext";
import { useMutationCorrelationContext, useMutationStateForMutation } from "./MutationContext";
interface Mutation<Input extends unknown[]> {
    execute: (...input: Input) => Promise<void>;
    isExecuting: boolean;
    error: Error | null;
}
/**
 * @template Output The output from the action, passed to afterComplete
 * @template Input Any input values that are used during the mutation
 */
interface MutationConfiguration<Input extends unknown[], Output> {
    /**
     * Each mutation needs a name to describe what it is doing.
     * This is used as part of the correlation context and is available in log messages.
     * Examples: "Revoke signing key", "Save deployment process", "Check target health"
     */
    name: string;
    /**
     * The mutation itself. Anything that modifies server state (i.e. executing an API command) should be in this function.
     * Anything that updates react component state or doesn't involve executing a command should be in `afterComplete`.
     * @param {Repository} repository
     * @param {Input} input
     * @returns {Promise<Output>} An output value can be passed to `afterComplete`, where it can be used to update component state.
     */
    action: (repository: Repository, ...input: Input) => Promise<Output>;
    /**
     * A function to execute after the mutation is complete.
     * Commonly used to update component state.
     * This is separate from the action because this function is cancellable (e.g. if navigating away while it is running)
     * @param {Repository} repository Any requests from this repository will be cancelled if the component unmounts while afterComplete is executing
     * @param {Output} output The output from the action function (if any)
     * @returns {Promise<void>}
     */
    afterComplete?: (repository: Repository, output: Output) => Promise<void>;
    /**
     * A function to execute after the mutation fails with an Error.
     * Commonly used as to log failures.
     * This is separate from the action because this function is cancellable (e.g. if navigating away while it is running)
     * @param {Error} error The error that occurred during the mutation
     */
    onError?: (error: Error) => void;
}
export function useMutation<Input extends unknown[], Output>(config: MutationConfiguration<Input, Output>): Mutation<Input> {
    const mutationHookInstanceId = useMutationHookInstanceId();
    const [mutationState, setMutationState] = useMutationStateForMutation(mutationHookInstanceId);
    const client = useOctopusClient();
    // Commonly, people will supply config values in a way that recreates the config object every render
    // We don't want this to trigger a new instance of the execute callback, because that should remain stable
    // But we do want the execute callback to reflect the latest values, in case the provided config values happen to close over important state
    const configRef = useRef(config);
    // Setting the current value of the ref is a little bit naughty according to the react docs, which state
    // "Do not write or read ref.current during rendering."
    // But I think it is appropriate in this case.
    configRef.current = config;
    const mutationCorrelationContextFromProvider = useMutationCorrelationContext();
    const mutationCorrelationContext = useMemo(() => ({
        ...mutationCorrelationContextFromProvider,
        mutationhookinstanceid: mutationHookInstanceId,
        mutation: configRef.current.name,
    } satisfies RequestCorrelationContext), [mutationCorrelationContextFromProvider, mutationHookInstanceId]);
    const abortSignal = useAbortSignal(setMutationState);
    const execute = useCallback((...input: Input) => {
        // We obtain the current value of the config ref here, rather than invoke the properties off the ref inside executeMutation
        // In practice, this means that afterComplete will be invoked as it existed when the mutation _started_.
        return executeMutation(setMutationState, configRef.current, client, mutationCorrelationContext, abortSignal, ...input);
    }, [abortSignal, client, mutationCorrelationContext, setMutationState] // These should all be stable dependencies across the lifetime of the hook
    );
    return {
        execute,
        isExecuting: mutationState.isExecuting,
        error: mutationState.error,
    };
}
async function executeMutation<Input extends unknown[], Output>(mutationActions: MutationActionsForMutation, config: MutationConfiguration<Input, Output>, client: Client, mutationCorrelationContext: RequestCorrelationContext, abortSignal: AbortSignal, ...input: Input) {
    const mutationInvocationId = v4();
    const mutationInvocationCorrelationContext: RequestCorrelationContext = { mutationinvocationid: mutationInvocationId, ...mutationCorrelationContext };
    const mutationLogger = logger.forContext(mutationInvocationCorrelationContext);
    mutationActions.start(mutationLogger);
    mutationLogger.debug("Starting mutation '{mutation}'", { mutation: config.name });
    try {
        const output = await config.action(createActionRepository(client, mutationInvocationCorrelationContext), ...input);
        mutationLogger.debug("Mutation '{mutation}' finished", { mutation: config.name });
        if (abortSignal.aborted) {
            mutationLogger.debug("Skipping afterComplete callback because the '{mutation}' mutation has been cancelled", { mutation: config.name });
            return;
        }
        mutationLogger.debug("Starting afterComplete callback for '{mutation}' mutation", { mutation: config.name });
        await config.afterComplete?.(createAfterCompleteRepository(client, mutationInvocationCorrelationContext, abortSignal), output);
        mutationLogger.debug("Finished afterComplete callback for '{mutation}' mutation", { mutation: config.name });
    }
    catch (error: unknown) {
        mutationLogger.debug("Error during '{mutation}' mutation", { mutation: config.name, error });
        if (abortSignal.aborted) {
            mutationLogger.debug("Skipping onError callback because the '{mutation}' mutation has been cancelled", { mutation: config.name });
            throw error;
        }
        const errorObject: Error = error instanceof Error ? error : new Error(`Unknown error: ${JSON.stringify(error)}`);
        config.onError?.(errorObject);
        mutationActions.error(errorObject, mutationLogger);
        throw error;
    }
    if (abortSignal.aborted) {
        return;
    }
    mutationActions.finish(mutationLogger);
}
function createActionRepository(client: Client, correlationContext: RequestCorrelationContext) {
    // in-flight mutations are _never_ cancelled
    // Someone might click a button to run a mutation and then successfully navigate away from a page
    // In this case, we want the mutation to continue. Cancelling it mid-flight might be unexpected
    // as well as making it ambiguous whether the mutation succeeded or not based on the cancellation state
    const abortSignal = new AbortController().signal;
    return new Repository(client, {
        correlationContext,
        abortSignal,
    });
}
function createAfterCompleteRepository(client: Client, correlationContext: RequestCorrelationContext, abortSignal: AbortSignal) {
    return new Repository(client, {
        correlationContext,
        abortSignal,
    });
}
function useAbortSignal(setMutationState: MutationActionsForMutation) {
    const abortController = useMemo(() => new AbortController(), []);
    useEffect(() => () => {
        if (!abortController.signal.aborted) {
            abortController.abort();
            setMutationState.unmount();
        }
    }, [abortController, setMutationState]);
    return abortController.signal;
}
function useMutationHookInstanceId(): string {
    return useMemo(() => v4(), []);
}
